import React from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    main,
    rightWrapperContent,
    button,
    backgroundImage,
    titleContainer,
    title,
    subtitle,
    topPadding,
    desktop,
    mobile,
    visible,
    hidden,
    leftWrapper,
    leftWrapperFirst,
    rightWrapperContentTitle,
    rightWrapper,
    rightWrapperTitle,
    buttonNew,
} from './home-about-continue-section.module.scss';

import Section from '../../../molecules/section';
import getTranslationKey from '../../../../utils/get-translation-key';
import Link from '../../../atoms/link';
import SectionWrapper from '../../../molecules/section-wrapper';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import Button from '../../../atoms/button';
import Quote from '../../../molecules/quote';

export interface HomeAboutContinueSectionProps {
    withTopPadding?: boolean;
}

export default function HomeAboutContinueSection({ withTopPadding }: HomeAboutContinueSectionProps) {
    const { t } = useI18next();
    const [
        backgroundRef,
        backgroundEntry,
    ] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.5,
    });
    const aboutTranslations = getTranslationKey('home', 'clothes');

    return (
        <SectionWrapper
            bgColor={'violet-dark'}
            className={`${main} ${withTopPadding ? topPadding : ''}`}
        >
            <div className={leftWrapper}>
                <div className={leftWrapperFirst}>
                    <Quote text={"\"Moda to sztuka, a Ty jesteś płótnem.\""} subtext={"Anonimowy"}></Quote>
                </div>
            </div>

            <div className={rightWrapper}>
                <div className={rightWrapperContent}>
                    <h2 className={rightWrapperContentTitle}>Niech Twoje modowe marzenia staną się rzeczywistością z Fashion Fairy!</h2>
                    <div>Przeglądanie tysięcy zdjęć ubrań online może być przytłaczające i czasochłonne. Szukasz czegoś idealnego na specjalną okazję lub na co dzień, a zamiast tego tracisz godziny, a nawet dni, przeglądając kolejne strony. Frustracja narasta, a znalezienie nowej stylizacji wydaje się niemożliwe.
                        I właśnie dlatego powstał serwis Fashion Fairy!
                    </div>
                    <div>Zaufaj naszej Wróżce Stylistce, wypełnij quiz i ciesz się spersonalizowanymi propozycjami, które oszczędzą Twój czas i sprawią, że poczujesz się wyjątkowo.
                    </div>
                    <div>Kliknij „Wystylizuj mnie” i odkryj swój idealny styl już teraz!</div>
                </div>
                <div className={rightWrapperTitle}>
                    <Quote text={"\"Moda to sztuka, a Ty jesteś płótnem.\""} subtext={"Anonimowy"}></Quote>
                </div>
            </div>
        </SectionWrapper>
    );
}

// extracted by mini-css-extract-plugin
export var backgroundImage = "home-main-section-module--background-image--+mx1j";
export var button = "home-main-section-module--button--vdxl7";
export var buttonNew = "home-main-section-module--button-new--+EAl1";
export var content = "home-main-section-module--content--XCNA7";
export var contentWrapper = "home-main-section-module--content-wrapper--yueeo";
export var hidden = "home-main-section-module--hidden--kkk1Q";
export var main = "home-main-section-module--main--dXl5X";
export var subtitle = "home-main-section-module--subtitle--Mcisi";
export var title = "home-main-section-module--title--2yB2l";
export var titleContainer = "home-main-section-module--title-container--kTLbi";
export var topPadding = "home-main-section-module--top-padding--BpkcC";
export var visible = "home-main-section-module--visible--db1ST";
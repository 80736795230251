// extracted by mini-css-extract-plugin
export var backgroundImage = "home-about-section-module--background-image--Ed+7J";
export var button = "home-about-section-module--button--bM8Dj";
export var buttonNew = "home-about-section-module--button-new--OquH7";
export var content = "home-about-section-module--content--qDckP";
export var contentFirst = "home-about-section-module--content-first--1-n3y";
export var contentFirstMobile = "home-about-section-module--content-first-mobile--TJRtR";
export var contentTitle = "home-about-section-module--content-title--l+oom";
export var contentWrapper = "home-about-section-module--content-wrapper--2iu1G";
export var hidden = "home-about-section-module--hidden--SoTLq";
export var main = "home-about-section-module--main--AYmon";
export var quoteFirst = "home-about-section-module--quoteFirst--5lWLV";
export var quoteTwo = "home-about-section-module--quoteTwo--BXHUv";
export var secondWrapper = "home-about-section-module--second-wrapper--aABrN";
export var secondWrapperTitle = "home-about-section-module--second-wrapper-title--ahY8B";
export var subtitle = "home-about-section-module--subtitle--PfpQh";
export var titleContainer = "home-about-section-module--title-container--Km4ku";
export var topPadding = "home-about-section-module--top-padding--QjNX2";
export var visible = "home-about-section-module--visible--6NbH5";
import React from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    main,
    content,
    button,
    backgroundImage,
    titleContainer,
    title,
    subtitle,
    topPadding,
    desktop,
    mobile,
    visible,
    hidden,
    contentWrapper,
    secondWrapper,
} from './home-survey-section.module.scss';

import Section from '../../../molecules/section';
import getTranslationKey from '../../../../utils/get-translation-key';
import Link from '../../../atoms/link';
import SectionWrapper from '../../../molecules/section-wrapper';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import Button from '../../../atoms/button';
import Wand from '../../../../assets/images/svg/wand_2.svg';
import ButtonWand from '../../../molecules/button-wand';

export interface HomeSurveySectionProps {
    withTopPadding?: boolean;
}

export default function HomeSurveySection({ withTopPadding }: HomeSurveySectionProps) {
    const { t } = useI18next();
    const [
        backgroundRef,
        backgroundEntry,
    ] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.5,
    });
    const aboutTranslations = getTranslationKey('home', 'survey');

    return (
        <SectionWrapper
            bgColor={'violet-dark'}
            className={`${main} ${withTopPadding ? topPadding : ''}`}
        >
            <div className={contentWrapper}>
                <div className={content}>
                    <h1>Wirtualna stylistka - jak to działa</h1>
                    <h1>Krok po kroku</h1>
                    <ol>
                        <li>
                            <div>
                                <h2>Uzupełnij quiz</h2>
                                <p>Odpowiedz na nasze pytania szczerze i precyzyjnie, a efekt końcowy będzie wyjątkowy i dopasowany do Twojego stylu.</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h2>Analiza stylisty</h2>
                                <p>Nasz zaawansowany algorytm, stworzony przez ekspertów w dziedzinie mody i technologii, analizuje Twoje odpowiedzi, aby dobrać idealne stylizacje specjalnie dla Ciebie.</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h2>Wybierz okazję</h2>
                                <p>Wybierz spośród różnych okazji tę, na którą szukasz stylizacji. Niezależnie czy to randka, spotkanie biznesowe, czy codzienny look – mamy coś dla Ciebie.</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h2>Dokonaj wyboru</h2>
                                <p>Spośród starannie wyselekcjonowanych propozycji wybierz tę stylizację, która najlepiej odpowiada Twoim potrzebom i gustowi.</p>
                            </div>
                        </li>
                    </ol>
                    <ButtonWand text={t(aboutTranslations('button'))}></ButtonWand>
                    <span>Kliknij "Wystylizuj mnie", zaufaj wróżce stylistce i cisze się darmową, spersonalizowaną modą już teraz</span>
                </div>
            </div>
        </SectionWrapper>
    );
}

import React from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    main,
    content,
    button,
    backgroundImage,
    titleContainer,
    title,
    subtitle,
    topPadding,
    desktop,
    mobile,
    visible,
    hidden,
    contentWrapper,
    contentFirst,
    contentTitle,
    secondWrapper,
    secondWrapperTitle,
    buttonNew,
    quoteFirst,
    contentFirstMobile,
    quoteTwo,
} from './home-about-section.module.scss';
import AboutBackground from '../../../../assets/images/svg/Fashion-fairy-logo.svg';
import AboutBackgroundMobile from '../../../../assets/images/svg/modatoty-mobile.svg';

import Section from '../../../molecules/section';
import getTranslationKey from '../../../../utils/get-translation-key';
import Link from '../../../atoms/link';
import SectionWrapper from '../../../molecules/section-wrapper';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import Button from '../../../atoms/button';
import Quote from '../../../molecules/quote';

export interface HomeAboutSectionProps {
    withTopPadding?: boolean;
}

export default function HomeAboutSection({ withTopPadding }: HomeAboutSectionProps) {
    const { t } = useI18next();
    const [
        backgroundRef,
        backgroundEntry,
    ] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.5,
    });
    const aboutTranslations = getTranslationKey('home', 'clothes');

    return (
        <SectionWrapper
            bgColor={'violet-dark'}
            className={`${main} ${withTopPadding ? topPadding : ''}`}
        >
            <div className={contentWrapper}>
                <div className={contentFirst}>
                    <Quote className={quoteFirst} text={"\"Zaufaj Wróżce Stylistce i odkryj swój unikalny styl.\""} subtext={"Fashion Fairy"}></Quote>
                </div>
                <div className={contentFirstMobile}>
                    <Quote className={quoteFirst} text={"\"Stylizacja na miarę Twojej osobowości\""} subtext={"Fashion Fairy"}></Quote>
                </div>
            </div>

            <div className={secondWrapper}>
                <div className={secondWrapperTitle}>
                    <Quote className={quoteTwo} text={"\"Stylizacja na miarę Twojej osobowości\""} subtext={"Fashion Fairy"}></Quote>
                </div>
                <div className={content}>
                    <h2 className={contentTitle}>To ja FashionFairy</h2>
                    <div>Mam na imię Emilia i jestem profesjonalną stylistką, specjalistką od wizerunku, dziennikarką modową oraz artystką. Z pasji do mody i technologii stworzyłam Fashion Fairy – unikalny projekt, który pomaga kobietom lepiej rozumieć modę i tworzyć stylizacje, stworzone specjalnie dla Ciebie.  Dzięki mojej wiedzy i doświadczeniu nie tylko piszę bloga i poruszam ważne tematy, ale również oferuję quiz stylistyczny, który dostosowuje garderobę do Twoich potrzeb i preferencji. </div>
                    <div>Zaufaj mi, wejdź w świat Fashion Fairy i ciesz się przemyślaną modą na najwyższym poziomie!</div>
                </div>
            </div>
        </SectionWrapper>
    );
}

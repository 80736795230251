// extracted by mini-css-extract-plugin
export var backgroundImage = "home-about-continue-section-module--background-image--msPwo";
export var button = "home-about-continue-section-module--button--qFFmg";
export var buttonNew = "home-about-continue-section-module--button-new--jj2kb";
export var hidden = "home-about-continue-section-module--hidden--HgWey";
export var leftWrapper = "home-about-continue-section-module--left-wrapper--Sfu+s";
export var leftWrapperFirst = "home-about-continue-section-module--left-wrapper-first--1KvYa";
export var main = "home-about-continue-section-module--main--12bI3";
export var rightWrapper = "home-about-continue-section-module--right-wrapper--12fD8";
export var rightWrapperContent = "home-about-continue-section-module--right-wrapper-content---+oTs";
export var rightWrapperContentTitle = "home-about-continue-section-module--right-wrapper-content-title--sCw3P";
export var rightWrapperTitle = "home-about-continue-section-module--right-wrapper-title--OoLLv";
export var subtitle = "home-about-continue-section-module--subtitle--cCVUf";
export var titleContainer = "home-about-continue-section-module--title-container--8K4XZ";
export var topPadding = "home-about-continue-section-module--top-padding--txvx5";
export var visible = "home-about-continue-section-module--visible--UpISd";
import React from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    main,
    content,
    button,
    backgroundImage,
    titleContainer,
    title,
    subtitle,
    topPadding,
    desktop,
    mobile,
    visible,
    hidden,
    contentWrapper,
    secondWrapper,
    buttonNew,
} from './home-quote-section.module.scss';

import Section from '../../../molecules/section';
import getTranslationKey from '../../../../utils/get-translation-key';
import Link from '../../../atoms/link';
import SectionWrapper from '../../../molecules/section-wrapper';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import Button from '../../../atoms/button';
import Quote from '../../../molecules/quote';

export interface HomeQuoteSectionProps {
    withTopPadding?: boolean;
}

export default function HomeQuoteSection({ withTopPadding }: HomeQuoteSectionProps) {
    const { t } = useI18next();
    const [
        backgroundRef,
        backgroundEntry,
    ] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.5,
    });
    const aboutTranslations = getTranslationKey('home', 'quote');

    return (
        <SectionWrapper
            bgColor={'violet-dark'}
            className={`${main} ${withTopPadding ? topPadding : ''}`}
        >
            <div className={contentWrapper}>
                <div className={content}>

                    <Quote text={"\"Twoja moda, Twoja historia. Opowiedz ją z Fashion Fairy\""} subtext={"Fashion Fairy"}></Quote>
                </div>
            </div>
        </SectionWrapper>
    );
}

import { Link } from "gatsby";
import React from "react";

import {
    quote
} from './quote.module.scss';


interface IQuoteProps {
    className?: string,
    text?: string,
    subtext?: string
}

const Quote: React.FC<IQuoteProps> = ({
    className = '',
    text = '',
    subtext = ''
}) => {
    return (
        <div className={`${quote} ${className}`}>
            <h3>{text}</h3>
            <p>{subtext}</p>
        </div>
    );
};


export default Quote;
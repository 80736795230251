import React from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    main,
    content,
    button,
    backgroundImage,
    titleContainer,
    title,
    subtitle,
    topPadding,
    desktop,
    mobile,
    visible,
    hidden,
    contentWrapper,
    buttonNew,
} from './home-main-section.module.scss';
import AboutBackground from '../../../../assets/images/svg/Fashion-fairy-logo.svg';
import AboutBackgroundMobile from '../../../../assets/images/svg/modatoty-mobile.svg';

import Section from '../../../molecules/section';
import getTranslationKey from '../../../../utils/get-translation-key';
import Link from '../../../atoms/link';
import SectionWrapper from '../../../molecules/section-wrapper';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import Button from '../../../atoms/button';
import ButtonWand from '../../../molecules/button-wand';

export interface HomeMainSectionProps {
    withTopPadding?: boolean;
}

export default function HomeMainSection({ withTopPadding }: HomeMainSectionProps) {
    const { t } = useI18next();
    const [
        backgroundRef,
        backgroundEntry,
    ] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.5,
    });
    const aboutTranslations = getTranslationKey('home', 'main');

    return (
        <SectionWrapper
            bgColor={'violet-dark'}
            className={`${main} ${withTopPadding ? topPadding : ''}`}
        >
            <Section
                ref={backgroundRef}
                className={`${backgroundImage} ${
                    backgroundEntry?.isIntersecting ? visible : hidden
                }`}
            >
                <AboutBackground  />
            </Section>
            <Section
                ref={contentRef}
                column={'narrow'}
                className={`${contentWrapper} ${
                    contentEntry?.isIntersecting ? visible : hidden
                }`}
                title={{
                    size: 'medium',
                    subtitle: '',
                    containerClassName: titleContainer,
                    className: title,
                    subtitleClassName: subtitle,
                    children: t(aboutTranslations('title')),
                    align: 'center',
                    visible: contentEntry?.isIntersecting,
                }}
            >
                <p className={content}>FASHION FAIRY TO INNOWACYJNY PROJEKT, KTÓRY ŁĄCZY BLOG MODOWY Z QUIZEM STYLISTYCZNYM. ZAMIAST GODZINAMI SZUKAĆ STYLIZACJI W MILIONACH SKLEPÓW, WYPEŁNIJ QUIZ I OTRZYMAJ PROPOZYCJE DOPASOWANE DO TWOICH PREFERENCJI. ZNAJDZIESZ TU ZBIÓR UBRAŃ Z RÓŻNYCH SKLEPÓW I OSZCZĘDZISZ CZAS I OTRZYMUJĄC IDEALNE UBRANIA NA KAŻDĄ OKAZJĘ.</p>
                <p className={content}>KLIKNIJ „WYSTYLIZUJ MNIE”, ZAUFAJ WRÓŻCE STYLISTCE I CIESZ SIĘ DARMOWĄ, SPERSONALIZOWANĄ MODĄ JUŻ TERAZ</p>
                
                <ButtonWand text={t(aboutTranslations('button'))}></ButtonWand>

                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </Section>
        </SectionWrapper>
    );
}

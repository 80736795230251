// extracted by mini-css-extract-plugin
export var backgroundImage = "home-clothes-section-module--background-image--Sol-s";
export var button = "home-clothes-section-module--button--ORmXx";
export var buttonNew = "home-clothes-section-module--button-new--bBx4W";
export var content = "home-clothes-section-module--content--65pf8";
export var contentWrapper = "home-clothes-section-module--content-wrapper--dH4t1";
export var hidden = "home-clothes-section-module--hidden--ikyzM";
export var main = "home-clothes-section-module--main--H7iSj";
export var secondWrapper = "home-clothes-section-module--second-wrapper--ZNROZ";
export var subtitle = "home-clothes-section-module--subtitle--fi3Qx";
export var title = "home-clothes-section-module--title--PYdC1";
export var titleContainer = "home-clothes-section-module--title-container--2Qzkw";
export var topPadding = "home-clothes-section-module--top-padding--MLGly";
export var visible = "home-clothes-section-module--visible--s-pgv";
import React from "react";
import { graphql } from "gatsby";
import { ImageDataLike } from "gatsby-plugin-image";

import MainLayout from "../../layouts/main-layout";
import { IBanner } from "../../models/banner.model";
import { IPostBrief } from "../../models/post.model";
import { IAuthor } from "../../models/author.model";
import { IInstagramPost } from "../../models/instagram-post.model";
import {ISliderProduct} from "../../models/slider-product.model";
import QueryEdgeNode from "../../models/query-edge-node.model";
import getNodes from "../../utils/get-nodes";
import getPostsWithAuthor from "../../utils/get-posts-with-author";
import { ISite } from "../../models/site.model";
import { IPageBase } from "../../models/page-base.model";
import usePageMetadata from "../../hooks/use-page-metadata";

import HomeMainSection from "../organisms/sections/home/home-main-section";
import AboutSection from "../organisms/sections/home/about-section";
import BenefitsSection from "../organisms/sections/home/benefits-section";
import HowToSection from "../organisms/sections/home/how-to-section";
import BannerSection from "../organisms/sections/banner-section";
import InstagramSection from "../organisms/sections/home/instagram-section";
import PostsSection from "../organisms/sections/posts-section";
import SubsectionsAdvices from "../organisms/sections/home/subsections-advices";
import OpinionSlider from "../organisms/sections/home/opinion-slider";
import ProductsSlider from "../organisms/sections/home/products-slider";
import HomeClothesSection from "../organisms/sections/home/home-clothes-section";
import HomeAboutSection from "../organisms/sections/home/home-about-section";
import HomeQuoteSection from "../organisms/sections/home/home-quote-section";
import HomeAboutContinueSection from "../organisms/sections/home/home-about-continue-section";
import HomeSurveySection from "../organisms/sections/home/home-survey-section";
import HomeLastSection from "../organisms/sections/home/home-last-section";

interface IIndexProps extends IPageBase {
    readonly data: {
        bannersCollection: {
            banners: IBanner[];
        } | null;
        promotedPosts: QueryEdgeNode<IPostBrief>;
        authors: QueryEdgeNode<IAuthor>;
        allInstagram: QueryEdgeNode<IInstagramPost>;
        site: ISite;
        allSliderProduct: QueryEdgeNode<ISliderProduct>
    } & { [key: string]: ImageDataLike };
}

const IndexPage: React.FC<IIndexProps> = ({ data, pageContext }) => {
    const banners = data.bannersCollection?.banners || [];
    const promotedPosts = getNodes(data.promotedPosts);
    const authors = getNodes(data.authors);
    const instagramPosts = getNodes(data.allInstagram);
    const products = getNodes(data.allSliderProduct);
    const postsWithAuthor = getPostsWithAuthor(promotedPosts, authors);

    const areBanners = banners.length > 0;

    const { defaultStructuredData } = usePageMetadata(pageContext, data.site);

    return (
        <MainLayout
            headerColor={"white"}
            SEOProps={{ structuredData: defaultStructuredData }}
        >
            {/*areBanners && <BannerSection banners={banners} />*/}
            {/*<AboutSection withTopPadding={areBanners} />*/}
            <HomeMainSection withTopPadding={areBanners} />
            
            {/*POZNEJ ODKOMENTOWAC => <HomeAboutSection withTopPadding={areBanners} />*/}
            <HomeAboutSection withTopPadding={areBanners} />
            <HomeClothesSection withTopPadding={areBanners} />
            <HomeQuoteSection withTopPadding={areBanners} />
            <HomeAboutContinueSection withTopPadding={areBanners} />
            <HomeSurveySection withTopPadding={areBanners} />
            <HomeLastSection withTopPadding={areBanners} />
            {/*STYLIZACJE PROPONOWANE*/}
            {/*
                products && products.length > 0 &&
                <ProductsSlider products={products} />*/
            }
            {/*STYLIZACJE PROPONOWANE*/}
            {/*<BenefitsSection />
            <HowToSection />
            <SubsectionsAdvices data={data} pageContext={pageContext} />
            <OpinionSlider />*/}
            {/*POSTY */}
            {/*postsWithAuthor.length > 0 && (
                <PostsSection
                    posts={postsWithAuthor}
                    authors={authors}
                    titleKey="posts-section.title"
                    subtitleKey="common.blog"
                />
            )*/}
            {/*POSTY */}
            {instagramPosts.length > 0 && <InstagramSection posts={instagramPosts} />}
        </MainLayout>
    );
};

export const query = graphql`
    query($language: String!, $placeId: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        bannersCollection(placeId: { eq: $placeId }) {
            ...bannersCollectionFields
        }

        promotedPosts: allPost(sort: { fields: publishedAt, order: DESC }, limit: 4) {
            edges {
                node {
                    ...postBriefFields
                }
            }
        }

        authors: allAuthor {
            edges {
                node {
                    ...authorFields
                }
            }
        }
        
        allSliderProduct {
            edges {
                node {
                    productId
                    categoryGroup
                    category
                    brandName
                    url
                    affiliateUrl
                    imgUrl
                }
            }
        }

        allInstagram {
            edges {
                node {
                    ...instagramFields
                }
            }
        }

        coverImg: file(relativePath: { eq: "subsection-1.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        figures: file(relativePath: { eq: "subsection-2.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        mainImg: file(relativePath: { eq: "subsection-3.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }

        site {
            ...siteFields
        }
    }
`;

export default IndexPage;
